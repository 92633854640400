<template>
  <div>
    <b-card bg-variant="light" header="Materia Prima, Insumos y Materiales" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-agua"
                        label="Consumo mensual de agua (m3)"
                        label-for="agua"
                    >
                        <b-form-input
                            id="agua"
                            v-model="form.agua"
                            type="number"
                            min="0"
                            max="10000"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-energia"
                        label="Consumo mensual de energia (kwh)"
                        label-for="energia"
                    >
                        <b-form-input
                            id="energia"
                            v-model="form.energia"
                            type="number"
                            min="0"
                            max="10000"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
               
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-gas"
                        label="Consumo mensual de gas natural/GLP (m3)"
                        label-for="gas"
                    >
                        <b-form-input
                            id="gas"
                            v-model="form.gas"
                            type="number"
                            min="0"
                            max="10000"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-combustible"
                        label="Consumo mensual de combustible"
                        label-for="combustible"
                    >
                        <b-form-input
                            id="combustible"
                            v-model="form.combustible"
                            type="number"
                            min="0"
                            max="10000"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
               
               

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                        </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>

        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../../contantesErrores';
  export default {
      name: 'MateriaPrimaVigilanciaControl',
    data() {
      return {
        editar:false,
        form: {
            id: null,
            agua: null,
            energia: null,
            gas: null,
            combustible: null
        },
      }
    },
    async mounted(){
        if(this.visitaEnGestion.id){
            this.form.id=this.visitaEnGestion.id
            await this.cargarMateriaPrima(this.visitaEnGestion.id)
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion']),
        ...mapState('gestionarVisitasVigilanciaControl', ['resultMateriasPrimas', 'materiasPrimasCargada' ]),
    },
    methods: {
        ...mapActions(['activarLoading']),
        ...mapActions('gestionarVisitasVigilanciaControl', ['crearMateriaPrima', 'cargarMateriaPrimaById', 'editarMateriaPrima']),

        async cargarMateriaPrima(id){
            this.activarLoading(true)
            try{
                await this.cargarMateriaPrimaById(id)
                console.log('esto es lo cargado', this.materiasPrimasCargada[0])
                if(this.materiasPrimasCargada.length>0){
                    this.editar=true
                    this.form=this.materiasPrimasCargada[0]
                }
                this.activarLoading(false)
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Matería Prima, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            this.activarLoading(true)

            let dataGuardar = {
                mateprimainsumo: this.form
            }

             try{
                 if(this.editar){
                     await this.editarMateriaPrima(dataGuardar)
                 }else{
                     await this.crearMateriaPrima(dataGuardar)
                 }
                if(this.resultMateriasPrimas.data.status==200){
                    this.activarLoading(false)
                    this.modalOk()
                }
            }catch(e){
                this.activarLoading(false)
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                    this.cargarMateriaPrima(this.visitaEnGestion.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                })
                .catch(err => {
                    console.log(err)
                })
        }, 
        
    }
  }
</script>

<style scoped>
    
</style>